<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="listProducts"
      :loading="isLoadingProducts"
      :server-items-length="productsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
    >

    <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                  <table class="table_inventory">
                    <tr class="header">
                      <th> {{ $t("batch_number") }} </th>
                      <th> {{ $t("batch_expiration_date") }}</th>
                      <th> {{ $t("batch_quantity_real") }}</th>
                      <th> {{ $t("batch_quantity") }} </th>
                      <th> {{ $t("valorisation") }} </th>
                      <th> {{ $t("ecart_quantity") }}</th>
                    </tr>
                    <tr v-for="(next, i) in item.adjustement_lots_1" :key="i" class="elements">
                      <template v-if="item.adjustement_lots_1[i]?.adjustment_quantity - item.adjustement_lots_2[i]?.adjustment_quantity == 0">
                      <td>{{next!= undefined && next?.lot_number != undefined ? next?.lot_number : "" }}</td>
                      <td>{{next!= undefined && next?.expiration_date != undefined ? next?.expiration_date : "" }}</td>
                      <td>{{next!= undefined && next?.qte_in_lot  != undefined ? next?.qte_in_lot : "" }}</td>
                      <td>{{next!= undefined && next?.adjustment_quantity != undefined ? next?.adjustment_quantity : ""  }}</td>
                     
                      <td>{{next!= undefined && next?.adjustment_quantity != undefined ?

                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "DZD",
                      }).format(
                        next?.adjustment_quantity  * next?.lot_price_ht 
                      )                       
                       : 0  }}</td>
                      <td>{{next!= undefined && next?.adjustment_quantity != undefined ? next?.adjustment_quantity - next?.qte_in_lot : ""  }}</td>
                      </template>
                      <template v-else>
                      <td>{{next!= undefined && next?.lot_number != undefined ? next?.lot_number : "" }}</td>
                      <td>{{item?.adjustement_lots_3 != undefined && item?.adjustement_lots_3[i] != undefined ? item?.adjustement_lots_3[i]?.expiration_date: ""  }}</td>
                      <td>{{next!= undefined && next?.qte_in_lot  != undefined ? next?.qte_in_lot : "" }}</td>
                      <td>{{item?.adjustement_lots_3 != undefined && item?.adjustement_lots_3[i] != undefined ? item?.adjustement_lots_3[i]?.adjustment_quantity: "" }}</td>
                      <td>{{item?.adjustement_lots_3 != undefined && item?.adjustement_lots_3[i] != undefined ?
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "DZD",
                      }).format(
                        item?.adjustement_lots_3[i]?.adjustment_quantity * next?.lot_price_ht
                      ) 
                        :  0 }}</td>
                      <td>{{item?.adjustement_lots_3 != undefined && item?.adjustement_lots_3[i] != undefined ? item?.adjustement_lots_3[i]?.adjustment_quantity - next?.qte_in_lot :  0 }}</td>
                      </template> 
                    </tr> 
                  </table>    
                  </td>     
                </template>

             

      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.image"
          :src="item.image?.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <v-icon v-if="$admin.can('inventory-view')" small class="mr-2" @click="showItem(item)">
                mdi-eye
            </v-icon> -->
        <v-btn
          v-if="$admin.can('inventory-update')"
          x-small
          @click="editItem(item)"
        >
          <v-icon small class="mr-2"> mdi-pencil </v-icon>
          Add justification
        </v-btn>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="options.search"
              :label="$t('search')"
              class=""
              @keyup.enter="updateProductByEnterKey"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <edit-product-reason v-if="steps == 2"></edit-product-reason>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    editProductReason: () => import("../dialogs/UpdateInventoryItemReason.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingProducts: "products/isLoadingProducts",
      product: "inventories/product",
      listProducts: "inventories/listProducts",
      productsMeta: "inventories/productsMeta",
    }),
  },

  watch: {
    options: {
      async handler() {
        if (this.inventory_id && this.steps == 3) {
          this.is_loading_product = true;

          await this.$store
            .dispatch("inventories/listProductsWithQuantityGap", {
              inventory_id: this.inventory_id,
              ...this.options,
            })
            .then(() => {
              this.is_loading_product = false;
            });
        }
      },
      deep: true,
    },

    steps(val) {
      console.log(val);
      if (val == 3 && this.inventory_id) {
        this.is_loading_product = true;
        this.$store
          .dispatch("inventories/listProductsWithQuantityGap", {
            inventory_id: this.inventory_id,
            ...this.options,
          })
          .then(() => {
            this.is_loading_product = false;
          });
      }
    },

    inventory_id(val) {
      if (val && this.steps == 3) {
        this.is_loading_product = true;

        this.$store
          .dispatch("inventories/listProductsWithQuantityGap", {
            inventory_id: val,
            ...this.options,
          })
          .then(() => {
            this.is_loading_product = false;
          });
      }
    },
  },

  props: {
    inventory_id: {
      type: Number,
    },
    steps: {
      type: Number,
    },
  },

  data() {
    return {
      singleExpand:false,
      expanded:[],
      options: { itemsPerPage: 30 },
      is_loading_product: false,

      headers: [
      { text:this.$t("batch_number_dlc_quantity"), value: 'data-table-expand' },
        {
          text: this.$t("sku"),
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: this.$t("code"),
          align: "start",
          value: "code",
          sortable: true,
        },
        {
          text: this.$t("image"),
          align: "start",
          value: "image",
          sortable: false,
        },

        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    updateProductByEnterKey(event) {
      try {
        this.$store
          .dispatch("inventories/getProductBySKU", {
            inventory_id: this.inventory_id,
            sku: event.target.value,
          })
          .then(() => {
            if (this.product) {
              this.editItem(this.product);
            }
            this.search_product_input = "";
          });
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error?.response?.data?.message);
      }
    },

    // showItem(item) {
    //     this.$store.dispatch("inventories/openShowProduct", item)
    // },
    editItem(item) {
      this.$store.dispatch("inventories/openEditProduct", item);
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },
  },
};
</script>
